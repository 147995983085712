import React from 'react';
import { Str, getStr, getNum, itterate, itterate_type } from './../components/utils/String';
import { Header } from './../components/Header';
import { Photostack } from './../components/Photostack';
import { Featurebox } from './../components/Featurebox';
import { Foldout } from './../components/Foldout';

import link_icon from './../images/link-icon.png'
import { Parallax } from './../components/Parallax';
import { LogoGrid } from './../components/LogoGrid';
import { IconCard } from './../components/Cards';
import { TwitterFeed, InstagramFeed, FacebookFeed } from '../components/SocialFeeds';

const img_wall = 'images/logos/wall.png'
// const img_edeka = 'images/logos/edeka.png'
const img_KlassenLotterie = 'images/logos/PostCodeLotterie.png'
const img_diakonie = 'images/logos/diakonie.png'
const img_deutscher_staedtetag = 'images/logos/deutscher_staedtetag.png'
//const img_airbnb = 'images/logos/airbnb.png'
const img_simply_v = 'images/logos/simply_v.png'
const img_beisheim = 'images/logos/Logo_BeisheimStiftung_verkleinert.png'

const img_logo_bosch = 'images/logos/Bosch_COLOR_RGB.png'
const img_logo_Umweltstiftung_RGB = 'images/logos/Umweltstiftung_RGB.jpg'
const img_logo_dbu = 'images/logos/dbu.png'

//const img_alianz = 'images/logos/Allianz_Partner_Versicherer.png'

const img_deutscher_stiftungen = 'images/logos/deutscher_stiftungen.png';



export const Home = () => {


  var features = itterate({
    title: 'initiative.box.[#].title',
    img: 'initiative.box.[#].image',
    logo: 'initiative.box.[#].logo',
    url: 'initiative.box.[#].href'
  }, getNum('initiative.counter'));

  var foldouts = itterate({
    title: 'foldout.[#].title',
    text: 'foldout.[#].text'
  }, getNum('foldout.count'), itterate_type.idonly)


  const menu = itterate({
    title: 'header.menuitem.[#].title',
    href: 'header.menuitem.[#].href'
  }, getNum('header.menuitem.count'))

  const logos = [
    // {img:  img_visa, link:'https://visa.de'},
    //{ img: img_alianz},

    { img: img_logo_bosch },
    { img: img_logo_dbu },
    { img: img_KlassenLotterie },
    { img: img_deutscher_staedtetag },
    { img: img_diakonie },
    { img: img_beisheim },
    { img: img_simply_v },
    { img: img_wall },

    //{ img: img_logo_Umweltstiftung_RGB},
    // { img: img_logo_dbu},
  ];


  return (
    <div className="App" id="top">

      <Header mode="skyline" menu={menu} logoLink={'#top'}>
        <div className="container px-4 pt-4">
          <h1 className="na-h1 text-color-white text-center pb-4"><Str id="header.text" /></h1>
        </div>
      </Header>

      <article>
        <section className="container px-10 m-px-0">
          <div className="px-8 m-px-0">
            <Photostack src="/images/Biz-Collage-voucher.jpg"></Photostack>
          </div>
          <div className="text-center text-body px-10 m-px-2 pb-4">
            <div className="py-2"><Str id="top.text.1" /></div>
            <div className="py-2"><Str id="top.text.2" /></div>
          </div>
        </section>
{/* 
        <section className="text-center inner-shadow-top">
          <div className="container ">
            <div className="na-h2 pt-10 pb-4"><Str id="xmas.title" /></div>
            <img className="shadow-md " src="/images/xmas2023.jpg" style={{ maxWidth: '90%', transform: 'rotate(2deg)' }}></img>
            <div className="pt-4 pb-5"><Str id="xmas.text" /></div>
            <div className="pb-10">
              <a className="btn bg-green text-white" href="https://www.wirweihnachten.de/" target="_blank" rel="noreferrer" >Zur Aktionsseite</a>
            </div>
          </div>
        </section> */}

        <section className="inner-shadow-top m-px-1">

          <div className="container-wide">
            <h2 className="na-h2 text-center pt-6 pb-4"><Str id="initiative.title" /></h2>
            <div className="flex-x col-3 flex-x-center">
              {features.map((i, idx) => {
                return (
                  <Featurebox id={'feature-' + idx} title={i.title} img={i.img} key={idx} url={i.url}>
                    <div className="flex-x p-2 flex-y-center">
                      <div className="flex-1">
                        {i.logo !== '' && <img alt="feature" className="feature-logo absolute" src={'/images/' + i.logo} />}
                      </div>
                      <div className="text-color-grey-link pr-1">Zur Webseite</div>
                      <img className="icon" src={link_icon} alt="link" />
                    </div>
                  </Featurebox>
                );
              }
              )}
            </div>
          </div>

          <div className="container-narrow pt-8 pb-8">
            {foldouts.map((n, c) => (
              <Foldout key={c} title={getStr(n.title)} badgeClosed="/images/foldout_badge.png" badgeOpen="/images/foldout_badge_open.png">
                <Str id={n.text} />
              </Foldout>))}
          </div>
        </section>

        <section className="inner-shadow-top">
          <Parallax factor={1} preshift={0} src="/images/parallax_02_mobile.jpg" mobilesrc="/images/parallax_02_mobile.jpg" />
        </section>

        <section className="container-wide text-center px-4 m-px-1">
          <div className="container">
            <h2 className="na-h2 pt-6 pb-2"><Str id="our.partners.title" /></h2>
            <p className="pt-2 pb-4 text-color-grey-mid"><Str id="our.partners.text" /></p>
            <div className="pb-2 text-color-grey-mid"><Str id="our.partners.selection" /></div>
          </div>
          <div className="px-0 m-px-0">
            <LogoGrid items={logos} />
          </div>
          <h2 className="na-h2 pt-8 pb-2 "><Str id="member.of" /></h2>
          <div className="pb-8">
            <img src={img_deutscher_stiftungen} alt="Deutsche Stiftungen" style={{ width: '280px', height: 'auto' }} />
          </div>
        </section>

        {/* <section className="inner-shadow-top inner-shadow-bottom bg-grey-white">
                    <div className="container-wide">
                        <h2 className="na-h2 pt-6 pb-2 text-center"><Str id="active.here" /></h2>     
                        <div className="flex-x col-2 pt-4 pb-8">
                            <div className="px-1">
                                <IconCard className="hi-100" icon={ <img src="images/share_twitter@2x.png" alt="logo"/> } title={getStr('active.on.twitter')}>
                                    <TwitterFeed />                                    
                                </IconCard>
                            </div>
                            <div  className="px-1">
                                <IconCard className="hi-100" icon={ <img src="images/share_facebook@2x.png" alt="logo"/> } title={getStr('active.on.facebook')}>
                                    <FacebookFeed />
                                </IconCard>
                            </div>
                            <div className="px-1">
                                <IconCard className="hi-100" icon={ <img src="images/share_instagram@2x.png" alt="logo"/> } title={getStr('active.on.instagram')}>
                                    <InstagramFeed />
                                </IconCard>
                            </div>
                        </div>
                    </div>
                </section> */}

        <section className="pb-8 px-2">
          <h2 className="na-h2 text-center pt-8 pb-4"><Str id="book.caption"></Str></h2>
          <div className="container-800 bg-grey-white flex-x m-flex-wrap m-flex-x-center radius-4">
            <div className="text-center m-wi-100">
              <img className="inline-block" src="/images/book.png" alt="book" id="book"></img>
            </div>
            <div className="flex-y p-4">
              <h3 className="text-lg text-semibold pb-2"><Str id="book.title"></Str></h3>
              <p>
                <Str id="book.text"></Str>
              </p>
              <a href={getStr('book.link.href')}><Str id="book.link.text"></Str></a>
            </div>
          </div>

          <div className="text-center pt-6 pb-2">
            <Str id="newsletter.title"></Str><br />
            <a href="/Newsletter"><Str id="newsletter.linktext"></Str></a>
          </div>
        </section>


      </article>
    </div>
  );


}